.span-data{
    font-weight: 400;
    color: #696969;
}

.data-contain{
    flex-direction: column;
    display: flex;
}
span>strong, span>b{
    color: #3f3a3a;
}

.faturamneto-grid{

    width: 100%;
    overflow-x: auto;
    max-height: 500px;
}