.chat-sidebar {
  width: 25vw;
  height: 95vh;
}

.chat-container {
  display: flex;
  justify-content: space-between;
  padding: 5%;
  padding-top: 2.5vh;
  max-height: 100vh;
  overflow: hidden;
  background: linear-gradient(to top, white 65%, #1d4fd891 30%);
}

.chatbot-header {
  display: grid;
  background-color: #1d4fd8c4;
  height: 80px;
  padding: 10px;
  color: white;
  width: 100%;
}
.chat-user-mss {
  user-select: none;
  padding: 10px;
  display: flex;
  gap: 10px;
  cursor: pointer;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  transition: white;
  justify-content: space-between;
}
.chatbot-header-cnpj {
  font-size: small;
  font-weight: 300 !important;
  font-style: italic;
  font-family: sans-serif;
}

.chatbot-container {
  z-index: 50;
  padding-right: 10px;
  width: 75vw;
  height: 85vh;
}

.chatbot-open-btn {
  padding: 1rem;
  background-color: #2563eb;
  color: white;
  border-radius: 50%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s ease-in-out;
}

.chatbot-open-btn:hover {
  background-color: #1d4ed8;
}

.chatbot-wrapper {
  width: 100%;
  height: calc(95vh - 71px);
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}
.chat-user-box {
  background-color: white;

  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  height: 87vh;
}

.chatbot-messages {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
}

.chat-message {
  display: flex;
  margin-bottom: 1rem;
}

.chat-user {
  justify-content: flex-end;
}

.chat-system {
  justify-content: flex-start;
}

.chat-bubble {
  padding: 0.75rem;
  border-radius: 0.5rem;
  max-width: 75%;
}
.chat-bubble > span {
  font-family: sans-serif;
  font-weight: 300 !important;
}

.chat-user .chat-bubble {
  background-color: #2563eb;
  color: white;
  border-bottom-right-radius: 0;
}

.chat-system .chat-bubble {
  background-color: #e5e7eb;
  color: black;
  border-bottom-left-radius: 0;
}

.chat-buttons {
  margin-top: 0.5rem;
}

.chat-button {
  background-color: #2563eb;
  color: white;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  margin-right: 0.5rem;
  transition: background-color 0.2s ease-in-out;
}

.chat-button:hover {
  background-color: #1d4ed8;
}

.chat-timestamp {
  display: block;
  margin-top: 0.5rem;
  font-size: 0.75rem;
  color: rgb(212, 211, 211);
  font-style: italic;
}

.typing-indicator {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.typing-dot {
  width: 0.5rem;
  height: 0.5rem;
  background-color: #6b7280;
  border-radius: 50%;
  animation: pulse 1.5s infinite;
}

.chatbot-input {
  display: flex;
  padding: 1rem;
  background-color: #f3f4f6;
  border-top: 1px solid #e5e7eb;
}

.chat-input-field {
  flex: 1;
  padding: 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 0.5rem;
}

.chat-send-btn {
  margin-left: 0.5rem;
  padding: 0.7rem;
  background-color: #2563eb;
  color: white;
  border-color: #2563eb;
  border-radius: 50%;
  transition: background-color 0.2s ease-in-out;
}

.chat-send-btn:hover {
  background-color: #1d4ed8;
  border-color: #1d4ed8;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
}

.chat-user-image {
  width: 55px;
  height: 55px;
  border-radius: 50px;
  border: thin solid;
  border-color: #1d4ed8;
}

.mss-selected {
  background-color: rgb(233, 231, 231);
}
