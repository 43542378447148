.bi-header-description {
  font-size: 12px;
}

.bi-header-div {
  display: flex;
  flex-direction: column;
}

.bi-form-data {
  margin-top: 30px;
}

.form-bi {
  height: 37px;
}
