.div-route-main {
  padding-left: 350px;
  padding-top: 2em;
}

@media only screen and (max-width: 800px) {
  .div-sidebar-app {
    display: none !important;
  }
  .div-route-main {
    width: 100%;
    padding: 35px;
  }
}
